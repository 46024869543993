<template>
  <b-modal
    @hidden="$emit('custumChange')"
    ref="mailModal"
    id="mailModal"
    title="LISTE DES FACTURES A ENVOYER"
    modal-class="cutsom-modal-bootstrap "
    size="lg"
    no-close-on-backdrop
    :hide-footer="true"
  >
    <div class="mb-5 d-flex justify-content-center">
      <b-table
        show-empty
        id="my-table"
        class="custom-table-style table-width "
        :fields="fields"
        :items="computedGetDataToUse"
        bordered
        sticky-header
        hover
        head-variant="light"
        tbody-tr-class="ligneNormale"
        empty-text="Il n'y a aucun enregistrement à afficher"
      >
        <template class="check-th" v-slot:head()="data">
          {{ data.field.label }}
        </template>
        <template v-slot:cell(details)="data">
          <button
            class="btn btn-anomalie"
            v-if="data.item.FactureAvoir.length > 0"
            @click="data.toggleDetails"
          >
            <font-awesome-icon
              class="icon-plus"
              icon="plus-square"
              v-if="data.detailsShowing == false"
              title="Afficher les avoirs"
            />

            <font-awesome-icon
              class="icon-plus"
              icon="minus-square"
              v-if="data.detailsShowing == true"
              title="Masques les avoirs"
            />
          </button>
        </template>

        <template #row-details="data">
          <span v-if="data.item.FactureAvoir.length === 0"
            >Pas des factures avoir pour cette facture
          </span>
          <span
            v-else
            v-for="item in data.item.FactureAvoir"
            :key="item.id"
            class="couleur-facture-global align-td-item border-botom"
          >
            <td class="width-service fix-margin-td-avoir">
              <div
                class="block-service-style d-flex justify-content-center"
              ></div>
            </td>
            <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center">
                {{ item.type }}
              </div>
            </td>
            <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center">
                {{ item.vendeur.nom_compagnie }}
              </div>
            </td>
            <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center">
                {{ item.num }}
              </div>
            </td>

            <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center">
                {{
                  item && item.acheteur && item.acheteur.nom_compagnie === null
                    ? item.acheteur.prenom + ' ' + item.acheteur.nom_famille
                    : item && item.acheteur && item.acheteur.nom_compagnie
                    ? item.acheteur.nom_compagnie
                    : ' '
                }}
              </div>
            </td>
            <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center">
                {{ item.date_creation }}
              </div>
            </td>
            <!-- <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center">
                {{ data.item.etat == 'Annulée' ? 'Verrouillée' : '-' }}
              </div>
            </td> -->
            <!-- <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center">
                {{ item.date_paiement }}
              </div>
            </td> -->
            <!-- <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center">
                {{
                  data.item.categorie && data.item.categorie.nom_categorie
                    ? data.item.categorie.nom_categorie
                    : '-'
                }}
              </div>
            </td> -->
            <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center ">
                {{ item.total_net_ht_data_base }} {{ item.devise }}
              </div>
            </td>
            <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center ">
                {{ item.total_tva_data_base }} {{ item.devise }}
              </div>
            </td>
            <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center ">
                {{ item.total_ttc_data_base }} {{ item.devise }}
              </div>
            </td>
            <!-- <td class="fix-margin-td-avoir">
              <div class="block-service-style d-flex justify-content-center ">
                {{ item.confirme === true ? 'OUI' : 'NON' }}
              </div>
            </td> -->
            <!-- <td class="fix-margin-td-avoir">
              <div
                class="block-service-style d-flex justify-content-center m-1"
              ></div>
            </td> -->
          </span>
        </template>

        <template v-slot:cell(type)="data">
          {{ data.item.type }}
        </template>
        <template v-slot:cell(Vendeur)="data">
          {{ data.item.vendeur.nom_compagnie }}
        </template>
        <template v-slot:cell(num)="data">
          {{ data.item.num }}
        </template>
        <template v-slot:cell(total_net_ht)="data">
          {{ data.item.total_net_ht_data_base }} {{ data.item.devise }}
        </template>
        <template v-slot:cell(tva)="data">
          {{ data.item.total_tva_data_base }} {{ data.item.devise }}
        </template>
        <template v-slot:cell(total_ttc)="data">
          {{ data.item.total_ttc_data_base }} {{ data.item.devise }}
        </template>
        <!-- <template v-slot:cell(etat)="data">
          {{ data.item.etat }}
        </template> -->
        <template v-slot:cell(produit)="data">
          <div class="three-points-concats ml-3">
            {{ computedListProduct(data.item) }}
          </div>
        </template>
        <template v-slot:cell(Acheteur)="data">
          {{
            data.item &&
            data.item.acheteur &&
            data.item.acheteur.nom_compagnie === null
              ? data.item.acheteur.prenom + ' ' + data.item.acheteur.nom_famille
              : data.item &&
                data.item.acheteur &&
                data.item.acheteur.nom_compagnie
              ? data.item.acheteur.nom_compagnie
              : ' '
          }}
        </template>
        <template v-slot:cell(date_creation)="data">
          {{ data.item.date_creation | formateDate }}
        </template>
        <!-- <template v-slot:cell(date_paiement)="data">
          {{
            data.item && data.item.date_paiement ? data.item.date_paiement : '-'
          }}
        </template> -->
        <!-- <template v-slot:cell(Categorie)="data">
          {{
            data.item.categorie && data.item.categorie.nom_categorie
              ? data.item.categorie.nom_categorie
              : '-'
          }}
        </template> -->
        <!-- <template v-slot:cell(confirme)="data">
          {{ data.item.confirme === true ? 'OUI' : 'NON' }}
        </template> -->
        <!-- <template v-slot:cell(num_proforma)="data">
          {{
            data.item && data.item.num_proforma ? data.item.num_proforma : '-'
          }}
        </template> -->
        <template v-slot:cell(facture)> </template>
      </b-table>
    </div>
    <div class="actionModel d-flex justify-content-center">
      <b-button
        class="button-valide-style"
        @click="$emit('handleSendMail', selectedAcheteurVendeur)"
      >
        <span>
          Envoyer
          <div class="loading ml-2">
            <!-- <div class="spinner-border" role="status"></div> -->
          </div>
        </span>
      </b-button>
    </div>
    <div v-if="computedTotalNetHt > 0" class="style-somme-th-ttc">
      <p class="m-2">
        Total net ht:
        {{ computedTotalNetHt.toFixed(2).toLocaleString('en-US') }}€
        {{ '   ' }}Total ttc:
        {{ computedTotalTtc.toFixed(2).toLocaleString('en-US') }}€
      </p>
    </div>
    <div class="style-checkboxes">
      <b-form-checkbox-group
        v-model="selectedAcheteurVendeur"
        :options="optionToSend"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
      ></b-form-checkbox-group>
    </div>
  </b-modal>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      loading: false,
      selectedAcheteurVendeur: ['acheteur', 'vendeur'],
      fields: [
        {
          key: 'details',
          label: '',
          tdClass: 'width-service',
          thClass: 'width-service',
          views: true,
          for: 'all'
        },
        { key: 'type', label: 'TYPE ' },
        { key: 'Vendeur', label: 'FACTURE PAR' },
        {
          key: 'num',
          label: 'NUMERO'
        },
        { key: 'Acheteur', label: 'NOM CLIENT' },
        { key: 'date_creation', label: 'DATE DE CRÉATION' },
        // { key: 'etat', label: 'ETAT' },
        // {
        //   key: 'date_paiement',
        //   label: 'DATE DE PAIEMENT'
        // },
        // { key: 'Categorie', label: 'CATEGORIE' },
        { key: 'total_net_ht', label: 'MT NET HT' },
        {
          key: 'tva',
          label: 'MT TVA'
        },
        { key: 'total_ttc', label: 'MT TTC' }
        // { key: 'confirme', label: 'CONFIRMATION' },
        // { key: 'num_proforma', label: 'REFERENCE PROFORMA' }
      ],
      optionToSend: [
        { name: 'Acheteur', item: 'acheteur' },
        { name: 'Vendeur', item: 'vendeur' }
      ]
    };
  },
  filters: {
    formateDate: value => {
      return moment(new Date(value)).format('DD-MM-YYYY');
    }
  },
  props: {
    isOpen: { required: true },
    computedGetDataToUse: { required: true }
  },
  computed: {
    computedTotalNetHt() {
      let sum = 0;
      this.computedGetDataToUse.map(
        item =>
          (sum += parseFloat(
            item.total_net_ht_data_base?.replace(' ', '')?.replace(',', '.')
          ))
      );
      return sum;
    },
    computedTotalTtc() {
      let sum = 0;
      this.computedGetDataToUse.map(
        item =>
          (sum += parseFloat(
            item.total_ttc_data_base?.replace(' ', '')?.replace(',', '.')
          ))
      );
      return sum;
    }
  },
  methods: {},
  watch: {
    isOpen: function() {
      if (this.isOpen === true) {
        this.$refs['mailModal'].show();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.table-rapport-style .customTableFraisFactureLibre {
  .item-details-style {
    margin: 2px 0px;
  }
  td,
  th {
    font-size: 8.5px;
    padding: 2px 2px !important;
    min-width: 100px;
    width: 100px;
  }
  .width-service {
    min-width: 40px;
    width: 40px;
  }
}
.white-color-custom-select {
  background-color: #fff;
  color: #000;
}
.couleur-facture-global {
  background-color: #f0f1ff;
}
.red-color-custom-select {
  background: linear-gradient(
    to bottom,
    #f85032 0%,
    #f16f5c 0%,
    #f6290c 51%,
    #f02f17 71%,
    #e73827 100%
  );
  color: #fff;
}
.green-color-custom-select {
  background: linear-gradient(to bottom, #82bc40 0%, #47761d 100%);
  color: #fff;
}
.blue-color-custom-select {
  background: linear-gradient(#54b3ff, #0078d9);
  color: #fff;
}
.table-width {
  max-height: calc(100vh - 200px);
}
.style-somme-th-ttc {
  font-family: 'Montserrat', sans-serif;
  background-color: #d0e4f5;
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: auto;
  height: 35px;
  border-radius: 30px;
  z-index: 120;
}
.style-checkboxes {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: auto;
  height: 35px;
  border-radius: 30px;
  z-index: 120;
}
</style>
