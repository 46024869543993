<template>
  <div class="invest-frais">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-63-px">
            <span class="title-tabel">Date d'ébut</span>
          </div>

          <date-picker
            value-type="format"
            type="date"
            v-model="date_debut"
            :clear-button="true"
            :disabled-date="disabledStartDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
            @input="handleDateTimePicker"
          ></date-picker>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-63-px">
            <span class="title-tabel">Date fin</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            v-model="date_fin"
            :clear-button="true"
            :disabled-date="disabledEndDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
            @input="handleDateTimePicker"
          ></date-picker>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-63-px">
            <span class="title-tabel">Vendeur</span>
          </div>
          <multiselect
            v-model="vendeur"
            :options="getDataSciVendeurOnLine"
            :multiple="true"
            label="name"
            track-by="id"
            class="customSelectMultipleFilter"
            :showLabels="false"
            placeholder="sélectionner un vendeur"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :loading="getLoadingSciVendeurOnLine"
            @input="handleFilter"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                :class="{
                  'multiselect__single showBlock': !isOpen,
                  'multiselect__single ': isOpen
                }"
                v-if="values.length"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Client</span>
          </div>

          <multiselect
            v-model="acheteur"
            :options="getDataSciAcheteurOnLine"
            :multiple="true"
            label="name"
            track-by="id"
            class="customSelectMultipleFilter"
            :showLabels="false"
            placeholder="sélectionner un client"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :loading="getLoadingSciAcheteurOnLine"
            @input="handleFilter"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                :class="{
                  'multiselect__single showBlock': !isOpen,
                  'multiselect__single ': isOpen
                }"
                v-if="values.length"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-46-px">
            <span class="title-tabel">Etat</span>
          </div>
          <multiselect
            v-model="statut"
            :options="computedValidationListFilter"
            :multiple="true"
            label="text"
            track-by="value"
            class="customSelectMultipleFilter"
            :showLabels="false"
            placeholder="sélectionner un statut"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            @input="handleFilter"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                :class="{
                  'multiselect__single showBlock': !isOpen,
                  'multiselect__single ': isOpen
                }"
                v-if="values.length"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorFraisEnergieInvest" class="error">
            <ul v-if="Array.isArray(getErrorFraisEnergieInvest)" class="mb-0">
              <li v-for="(e, index) in getErrorFraisEnergieInvest" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorFraisEnergieInvest }}</div>
          </div>
        </div>
        <div
          v-if="getLoadingFraisEnergieInvest"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <search :value="searchValue" @changeSearchValue="changeSearchValue" />
          <b-button
            size="sm"
            class="button-export-style mr-2 ml-2"
            title="Envoyez un email aux sociéte sélectionnés"
            @click="openModal"
            v-if="
              computedGetDataToUse.length > 0 && !getLoadingFraisEnergieInvest
            "
          >
            <font-awesome-icon icon="envelope"
          /></b-button>
          <mail-modal
            :isOpen="isOpen"
            :computedGetDataToUse="computedGetDataToUse"
            @custumChange="closeModal"
            @handleSendMail="handleSendMail"
          />
          <b-dropdown size="sm" class="p-0 mr-3 ml-2 export-button-style">
            <template #button-content>
              <font-awesome-icon icon="cloud-download-alt"
            /></template>
            <b-dropdown-item
              v-if="
                (computedCheck || factureChecked.length > 0) &&
                  cantExport($options.name)
              "
              @click.prevent.stop="FacuresToDownloadPDF()"
            >
              PDF
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                (computedCheck || factureChecked.length > 0) &&
                  cantExport($options.name)
              "
              @click.prevent.stop="FacuresToDownloadZIP()"
            >
              ZIP
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                (computedCheck || factureChecked.length > 0) &&
                  cantExport($options.name)
              "
              @click.prevent.stop="FacuresToConfirme()"
            >
              Confirmer
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.exportXlsModal @click="initModal">
              Exporter xls
            </b-dropdown-item>
          </b-dropdown>
          <add-facture-energie-invest
            :month="currentMonth"
            :year="currentYear"
            @reloadData="reloadFactureData"
          />
        </div>
      </div>
    </div>
    <div class="body-box-rapport">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTbaleFraisComptaRh table-header"
          :items="computedFraisBddCommericalCactus"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div>
              <template v-if="data.field.key == 'check_all'">
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </template>
              <span
                v-else
                :class="{
                  'white-color': computedHeaderVisibility(data.field.key)
                }"
              >
                <template>{{ data.field.label }}</template>
              </span>
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkAllVerif(data.item.check)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(details)="data">
            <button
              class="btn btn-anomalie"
              v-if="data.item.FactureAvoir.length > 0"
              @click="data.toggleDetails"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les avoirs"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masques les avoirs"
              />
            </button>
          </template>

          <template #row-details="data">
            <span v-if="data.item.FactureAvoir.length === 0"
              >Pas des factures avoir pour cette facture
            </span>
            <span
              v-else
              v-for="item in data.item.FactureAvoir"
              :key="item.id"
              :class="
                item.motif_avoir === 'avoir_global'
                  ? 'couleur-facture-global align-td-item border-botom'
                  : 'align-td-item border-botom'
              "
            >
              <td class="check-column-frais">
                <div>
                  <b-form-checkbox
                    v-model="item.checkAvoir"
                    @change="checkOneAvoirFunction(item)"
                    class="check-th"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td class="check-column-frais">
                <div
                  class="block-service-style d-flex justify-content-center"
                ></div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.vendeur.nom_compagnie }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.acheteur.nom_compagnie }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.date_creation }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.type }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_ht }} €
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.produits[0].tva }} %
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_tva }} €
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_ttc }} €
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.num }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ data.item.etat == 'Annulée' ? 'Verrouillée' : '-' }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div
                  class="block-service-style d-flex justify-content-center m-1"
                >
                  <b-dropdown active="false" size="sm" class="p-0">
                    <template #button-content class="button-13">
                      <font-awesome-icon icon="bars" />
                    </template>
                    <b-dropdown-item @click.prevent.stop="visualisation(item)">
                      Afficher
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="cantExport($options.name)"
                      @click.prevent.stop="FacuresToDownloadOnePDF(item)"
                    >
                      Télécharger en pdf
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </span>
          </template>
          <template v-slot:cell(vendeur)="data">
            <span class="text-capitalize"
              >{{
                data.item.vendeur.nom_compagnie
                  ? data.item.vendeur.nom_compagnie
                  : '-'
              }}
            </span></template
          >
          <template v-slot:cell(acheteur)="data">
            <span class="text-capitalize"
              >{{
                data.item.acheteur.nom_compagnie
                  ? data.item.acheteur.nom_compagnie
                  : '-'
              }}
            </span></template
          >
          <template v-slot:cell(date_creation)="data"
            >{{ data.item.date_creation | formateDate }}
          </template>
          <template v-slot:cell(total_ht)="data">
            {{ data.item.total_ht }} €
          </template>
          <template v-slot:cell(tva)="data">
            {{ data.item.produits[0].tva }} %
          </template>
          <template v-slot:cell(total_tva)="data">
            {{ data.item.total_tva }} €
          </template>
          <template v-slot:cell(total_ttc)="data">
            {{ data.item.total_ttc }} €
          </template>
          <template v-slot:cell(etat)="data">
            <div
              class="custom-component-etat-facture"
              :class="{ open: data.item.openSelect == true }"
              v-click-outside="closeSelectEtat"
              :id="'component' + data.index"
            >
              <div
                :id="'etat-facture-' + data.item.id"
                class="b-form-select-custom-etat"
                @click="setVisibilityEtatList(data.item, data.index)"
                :class="{
                  'red-color-custom-select': data.item.etat == 'Payé en partie',
                  'white-color-custom-select': data.item.etat == 'Créé',
                  'green-color-custom-select': data.item.etat == 'Payé',
                  'blue-color-custom-select': data.item.etat == 'Envoyé'
                }"
              >
                <div id="content">
                  {{ data.item.etat }}
                  <font-awesome-icon
                    icon="caret-down"
                    class="icon-class-caret-down"
                  />
                </div>
                <div
                  id="background"
                  :class="{
                    'white-color-custom-select': data.item.etat == 'Créé',
                    'green-color-custom-select':
                      data.item.etat == 'Payé' ||
                      data.item.etat == 'Payé en partie',
                    'blue-color-custom-select': data.item.etat == 'Envoyé'
                  }"
                  :style="computedWidth(data.item)"
                ></div>
              </div>

              <ul
                class="dropdown-menu pull-right"
                role="menu"
                v-if="
                  data.item.openSelect == true &&
                    data.item.etat != 'Annulée' &&
                    data.item.etat != 'Verrouillée'
                "
              >
                <li
                  v-for="result in etatList"
                  :key="result.text + data.item.id"
                  @click="updateEtat(data.item, result)"
                >
                  {{ result.text }}
                </li>
              </ul>
            </div>
            <!-- <EditableInput
              :editable="
                isSuperAdminGetter ||
                  (cantUpdate($options.name) && data.item.statue != 'paye')
              "
              champName="statue"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue"
              :updateFunction="updateStatueFraisEnergiInvest"
              label="value"
              type_ste="filial"
              :positionSelect="true"
              formate="regieFacture"
              :showIconEdit="true"
            /> -->
          </template>
          <template v-slot:cell(actions)="data">
            <b-dropdown active="false" size="sm" class="p-0">
              <template #button-content class="button-13">
                <font-awesome-icon icon="bars" />
              </template>
              <b-dropdown-item @click.prevent.stop="visualisation(data.item)">
                Afficher
              </b-dropdown-item>
              <b-dropdown-item
                v-if="cantExport($options.name)"
                @click.prevent.stop="FacuresToDownloadOnePDF(data.item)"
              >
                Télécharger en pdf
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  (isSuperAdminGetter || data.item.user_id === idConnecter) &&
                    data.item.FactureAvoir.length === 0 &&
                    data.item.type == 'Facture'
                "
                @click.prevent.stop="CreateGlobalAvoir(data.item)"
              >
                Avoir sur Facture
              </b-dropdown-item>
            </b-dropdown>

            <!-- <font-awesome-icon
              v-if="cantDelete($options.name) && !data.item.delete"
              icon="trash"
              class="trash-style"
              @click="handleDeleteModal(data.item)"
              title="Supprimer la facture"
            /> -->
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getCountSci"
            :per-page="per_page"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="per_page"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <!-- Export Excel Modal -->
    <b-modal
      :hide-footer="true"
      no-close-on-backdrop
      id="exportXlsModal"
      title="Exportation xls"
      ref="refExportXlsModal"
    >
      <div class="d-flex justify-content-start text-dark mt-1">
        <p>Colonnes à afficher dans l'xls:</p>
      </div>
      <div class="row">
        <div class="col-6">
          <b-form-group label="Colonnes de facture:">
            <b-form-checkbox
              v-model="checkAllColumn"
              switch
              @change="changeAllCheckColumns"
              >Tous</b-form-checkbox
            >
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="selected"
              :options="options"
              name="flavour-1"
              stacked
              switches
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label="Colonnes des produits:">
            <b-form-checkbox
              v-model="checkAllColumnF"
              switch
              @change="changeAllCheckColumns"
              >Tous</b-form-checkbox
            >
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              :options="optionProduct"
              name="flavour-1"
              stacked
              switches
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError d-flex justify-content-center">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button class="button-valide-style" @click="handleExportFile">
            <span>
              Générer
              <div v-if="getLoadingFraisEnergieInvest" class="loading ml-2">
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- Modal visualisation facture -->
    <b-modal
      :hide-footer="true"
      no-close-on-backdrop
      id="visualisationFactureModal"
      title="Visualisation de facture"
      ref="visualisationFactureModal"
      modal-class="cutsom-modal-bootstrap-visualisation"
    >
      <div class="row">
        <iframe
          class="iframe-position"
          :src="domain + '' + factureToShow"
        ></iframe>
      </div>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError d-flex justify-content-center">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style"
            @click="hideModal('visualisationFactureModal')"
          >
            <span>
              Fermer
              <div v-if="getLoadingFraisEnergieInvest" class="loading ml-2">
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ClickOutside from 'vue-click-outside';
import MailModal from '../factureLibre/MailModal.vue';
import domain from '@/environment';

export default {
  name: 'Gestion-Invest',
  data() {
    return {
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      page: 1,
      per_page: 10,
      vendeur: [],
      acheteur: [],
      date_debut: null,
      date_fin: null,
      statut: [],
      fields: [
        {
          key: 'check_all',
          label: '',
          tdClass: 'check-column-frais',
          thClass: 'th-check-column-frais'
        },
        {
          key: 'details',
          label: '',
          tdClass: 'check-column-frais',
          thClass: 'th-check-column-frais',
          views: true,
          for: 'all'
        },
        {
          key: 'vendeur',
          label: 'Filiale Installateur',
          thClass: 'width-fixed',
          tdClass: 'width-fixed'
        },
        {
          key: 'acheteur',
          label: 'Filiale locataire',
          thClass: 'width-fixed',
          tdClass: 'width-fixed'
        },
        {
          key: 'date_creation',
          label: 'Date facture',
          thClass: 'width-fixed',
          tdClass: 'width-fixed'
        },
        {
          key: 'type',
          label: 'Type Document',
          thClass: 'width-fixed',
          tdClass: 'width-fixed'
        },
        ,
        {
          key: 'total_ht',
          label: 'Montant HT',
          thClass: 'bg-color-th-Energie-invest'
        },
        {
          key: 'tva',
          label: 'TVA %',
          thClass: 'bg-color-th-Energie-invest'
        },
        {
          key: 'total_tva',
          label: 'Montant TVA',
          thClass: 'bg-color-th-Energie-invest'
        },
        {
          key: 'total_ttc',
          label: 'Montant TTC',
          thClass: 'bg-color-th-Energie-invest'
        },

        {
          key: 'num',
          label: 'N° Facture',
          thClass: 'bg-color-th-Energie-invest'
        },
        {
          key: 'etat',
          label: 'Statut Facture',
          thClass: 'bg-color-th-Energie-invest'
        },
        {
          key: 'actions',
          label: '',
          thClass: 'width-fixed',
          tdClass: 'width-fixed'
        }
      ],
      sortBy: 'nom',
      searchValue: null,
      checkAll: false,
      indexSelectIsOpen: null,
      etatList: [
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' }
        // { value: 'Annulée', text: 'Annulée' }
      ],
      factureChecked: [],
      factureAvoirChecked: [],
      selected: [],
      checkAllColumn: true,
      checkAllColumnF: true,
      error: null,
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getFullYear(),
      options: [
        { text: 'Type Facture', value: 'type' },
        { text: 'Societe', value: 'societe' },
        { text: 'Numéro Facture', value: 'numero' },
        { text: 'Client', value: 'client' },
        { text: 'Montant NET HT', value: 'montant_net_ht' },
        { text: 'Montant TVA', value: 'montant_tva' },
        { text: 'Montant TTC', value: 'montant_ttc' },
        { text: 'Statut', value: 'statut' },
        { text: 'Objet', value: 'objet' },
        { text: 'Iban', value: 'iban' },
        { text: 'Bic', value: 'swift' },
        { text: 'Date paiement', value: 'date_paiement' },
        { text: 'Mode reglement', value: 'mode_reglement' },
        { text: 'Date de création', value: 'date' },
        { text: 'Catégorie', value: 'categorie' }
      ],
      optionProduct: [
        { text: 'Nom', value: 'nom_prod' },
        { text: 'Réfernce', value: 'ref_prod' },
        { text: 'Qantité', value: 'qte_prod' },
        { text: 'Unité ', value: 'unite_prod' },
        { text: 'PU HT', value: 'pu_ht_prod' },
        { text: 'Réduction', value: 'reduction_prod' },
        { text: 'NET HT', value: 'net_ht_prod' },
        { text: 'TVA', value: 'tva_prod' },
        { text: 'TTC', value: 'ttc_prod' },
        { text: 'Description', value: 'description_prod' }
      ],
      isOpen: false,
      factureToShow: null,
      domain: domain + '/api/libre/generate-pdf?id='
    };
  },
  filters: {
    formateDate: value => {
      return moment(new Date(value)).format('DD-MM-YYYY');
    }
  },
  computed: {
    ...mapGetters([
      'getDataSciVendeurOnLine',
      'getLoadingSciVendeurOnLine',
      'getDataSciAcheteurOnLine',
      'getLoadingSciAcheteurOnLine',
      'getCountSci',
      'isSuperAdminGetter',
      'getFraisEnergieInvest',
      'cantUpdate',
      'getLoadingFraisEnergieInvest',
      'getErrorFraisEnergieInvest',
      'cantCreate',
      'getUserData',
      'cantDelete',
      'cantSend',
      'getOtherErrorMailEnergiInvestMail',
      'getEmailLoading',
      'getFilialeSci',
      'getErrorDeleteFacureNotValide',
      'cantExport',
      'getLoadingMailFactureLibre',
      'getErrorMailingFactureLibre',
      'getSuccessSendMailsFactureLibre'
    ]),
    computedCheckedRows() {
      return this.getFraisEnergieInvest?.filter(item => item.check == true);
    },
    computedValidationList() {
      return [
        { value: 'Créé', full_name: 'Créé' },
        { value: 'Payé', full_name: 'Payé' },
        { value: 'Payé en partie', full_name: 'Payé en partie' },
        { value: 'Annulée', full_name: 'Annulée' }
      ];
    },
    computedValidationListFilter() {
      return [
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'Annulée', text: 'Annulée' }
      ];
    },
    computedFraisBddCommericalCactus() {
      return this.getFraisEnergieInvest;
    },
    computedFields() {
      return this.fields;
    },
    computedHeaderVisibility() {
      return function(data) {
        if (
          data != 'check_all' &&
          data != 'vendeur' &&
          data != 'acheteur' &&
          data != 'type' &&
          data != 'date_creation' &&
          data != 'filiale_associer'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedWidth() {
      return function(data) {
        if (data && data.etat && data.etat == 'Payé en partie') {
          let montant = (data.montant_payer * 100) / data.total_ttc;
          if (montant > 100) {
            montant = 100;
          }
          return { width: montant + '%' };
        } else {
          return { width: '0%' };
        }
      };
    },
    computedCheck() {
      let checkesRows = this?.getFraisEnergieInvest?.filter(
        row => row.check == true
      );
      if (checkesRows.length > 0) {
        return true;
      }
      return false;
    },
    computedGetDataToUse() {
      let checkesRows = this?.getFraisEnergieInvest?.filter(
        row => row.check === true && row.type !== "Facture d'avoir"
      );
      return checkesRows;
    }
  },
  methods: {
    ...mapActions([
      'getdataSciVendeurOnLine',
      'getdataSciAcheteurOnLine',
      'fetchFraisEnergiInvest',
      'refreshDataBddCommericalCactusCommercialCactus',
      'deleteFactureEnergiInvest',
      'getAllOnlineFilialeInFraisVisionFilialeEnergiInvest',
      'updateStatueFraisEnergiInvest',
      'deleteFactureEnergiInvest',
      'updatePaymentDate',
      'downloadOnePDF',
      'CreateBillAvoirGlobal',
      'downloadPDF',
      'downloadZIP',
      'transfromToFacture',
      'exportExcelFactureSci',
      'sendMailFactureLibre'
    ]),
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      //get list vendeur and acheteur
      this.vendeur = [];
      this.acheteur = [];
      this.dataSciOnLine();
      this.handleFilter();
    },
    disabledStartDate(date) {
      return this.date_fin && date >= new Date(this.date_fin);
    },
    disabledEndDate(date) {
      return this.date_debut && date < new Date(this.date_debut);
    },

    dataSciOnLine() {
      this.getdataSciVendeurOnLine({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      this.getdataSciAcheteurOnLine({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
    },
    handleFilter() {
      this.checkAll = false;
      this.setLocalStorageEnergieInvest();
      this.fetchFraisEnergiInvest({
        search: this.searchValue,
        statut: this.statut,
        vendeur: this.vendeur,
        acheteur: this.acheteur,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        page: this.page,
        per_page: this.per_page,
        order: 'ASC',
        column: 'date_creation',
        famille: 'sci'
      });
    },
    reloadData() {
      this.checkAll = false;
      this.handleFilter();
    },
    reloadFactureData(data) {
      this.reloadData();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return 'ligneEnCourDeValidation';
    },
    checkAllFunction() {
      this.getFraisEnergieInvest.map(item => (item.check = this.checkAll));
    },
    checkAllVerif(item) {
      if (item == false && this.checkAll == true) {
        this.checkAll = false;
      }
    },
    handleDeleteModal(item) {
      this.$confirm(
        '',
        'Êtes-vous sur de vouloir supprimer cette facture ?',
        'warning'
      ).then(async () => {
        const response = await this.deleteFactureEnergiInvest(item.id);
        if (response.succes) {
          this.flashMessage.show({
            status: 'success',
            title: 'success',
            clickable: true,
            time: 0,
            message: response.response
          });
        } else {
          this.flashMessage.show({
            status: 'error',
            title: 'Error',
            clickable: true,
            time: 0,
            message: response?.error
          });
        }
      });
    },
    setLocalStorageEnergieInvest() {
      localStorage.setItem(
        'energie-invest-sci',
        JSON.stringify({
          search: this.searchValue,
          statue: this.statut,
          vendeur: this.vendeur,
          acheteur: this.acheteur,
          date_debut: this.date_debut,
          date_fin: this.date_fin
        })
      );
    },
    setVisibilityEtatList(data, index) {
      data.openSelect = !data.openSelect;
      if (data.openSelect === true) {
        this.indexSelectIsOpen = { index: index, item: data };
      } else {
        this.indexSelectIsOpen = null;
      }
    },
    closeSelectEtat(event) {
      if (
        this.indexSelectIsOpen != null &&
        this.indexSelectIsOpen.item &&
        this.indexSelectIsOpen.item.openSelect == true
      ) {
        let tab = event.path.filter(
          item =>
            item.nodeName == 'DIV' &&
            item.getAttribute('id') ==
              'component' + this.indexSelectIsOpen.index
        );
        if (event.path && tab.length == 0) {
          this.computedFraisBddCommericalCactus[
            this.indexSelectIsOpen.index
          ].openSelect = false;
          this.indexSelectIsOpen = null;
        }
      }
    },
    async updateEtat(data, result) {
      data.etat = result.value;
      data.openSelect = false;
      await this.updatePaymentDate(data);
    },
    visualisation(item) {
      this.factureToShow = item.id;
      this.$refs['visualisationFactureModal'].show();
      // this.$router.push(`/display-facture/${item.id}`);
    },
    async FacuresToDownloadOnePDF(idPdf) {
      await this.downloadOnePDF(idPdf);
    },
    async CreateGlobalAvoir(data) {
      const response = await this.CreateBillAvoirGlobal(data);
      if (response.success) {
        this.flashMessage.show({
          status: 'success',
          title: '',
          clickable: true,
          time: 0,
          message: 'Une avoir sur ' + data.num + ' a été ajoutée'
        });
        await this.fetchFraisEnergiInvest({
          search: this.searchValue,
          statut: this.statut,
          vendeur: this.vendeur,
          acheteur: this.acheteur,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          page: this.page,
          per_page: this.per_page,
          order: 'DESC',
          column: 'id',
          famille: 'sci'
        });
      }
    },
    async FacuresToDownloadPDF() {
      let checkesRows = this?.getFraisEnergieInvest?.filter(
        row => row.check == true
      );
      let ids = [];
      let bodyFormData = new FormData();
      for (let i = 0; i < checkesRows.length; i++) {
        ids.push(checkesRows[i].id);
      }
      for (let i = 0; i < this.factureChecked.length; i++) {
        ids.push(this.factureChecked[i]);
      }
      for (let i = 0; i < ids.length; i++) {
        bodyFormData.append('ids[' + i + ']', ids[i]);
      }
      await this.downloadPDF(bodyFormData);
    },
    async FacuresToDownloadZIP() {
      let checkesRows = this?.getFraisEnergieInvest?.filter(
        row => row.check == true
      );
      let ids = [];
      let bodyFormData = new FormData();
      for (let i = 0; i < checkesRows.length; i++) {
        ids.push(checkesRows[i].id);
      }
      for (let i = 0; i < this.factureChecked.length; i++) {
        ids.push(this.factureChecked[i]);
      }
      for (let i = 0; i < ids.length; i++) {
        bodyFormData.append('ids[' + i + ']', ids[i]);
      }
      await this.downloadZIP(bodyFormData);
    },
    async FacuresToConfirme() {
      let checkesRows = this?.getFraisEnergieInvest?.filter(
        row => row.check == true
      );
      let ids = [];
      let bodyFormData = new FormData();
      for (let i = 0; i < checkesRows.length; i++) {
        ids.push(checkesRows[i].id);
      }
      for (let i = 0; i < this.factureChecked.length; i++) {
        ids.push(this.factureChecked[i]);
      }
      for (let i = 0; i < ids.length; i++) {
        bodyFormData.append('ids[' + i + ']', ids[i]);
      }
      const response = await this.transfromToFacture(bodyFormData);
      if (response === true) {
        await this.fetchFraisEnergiInvest({
          search: this.searchValue,
          statut: this.statut,
          vendeur: this.vendeur,
          acheteur: this.acheteur,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          page: this.page,
          per_page: this.per_page,
          order: 'DESC',
          column: 'id',
          famille: 'sci'
        });
      }
    },
    initModal() {
      this.selected = [
        'type',
        'numero',
        'date',
        'client',
        'statut',
        'montant_net_ht',
        'montant_ttc',
        'objet',
        'montant_tva',
        'societe',
        'iban',
        'swift',
        'date_paiement',
        'mode_reglement',
        'nom_prod',
        'ref_prod',
        'qte_prod',
        'unite_prod',
        'pu_ht_prod',
        'reduction_prod',
        'net_ht_prod',
        'tva_prod',
        'ttc_prod',
        'description_prod',
        'categorie'
      ];
      this.checkAllColumn = true;
      this.checkAllColumnF = true;
    },
    async handleExportFile() {
      let checkesRows = this?.getFraisEnergieInvest?.filter(
        row => row.check == true
      );
      var bodyFormData = new FormData();
      for (let i = 0; i < this.selected.length; i++) {
        bodyFormData.append('columns[' + [i] + ']', this.selected[i]);
      }
      let ids = [];

      for (let i = 0; i < checkesRows.length; i++) {
        if (checkesRows[i].type !== "Facture d'avoir") {
          ids.push(checkesRows[i].id);
          bodyFormData.append('ids[' + [i] + ']', checkesRows[i].id);
        }
      }
      if (this.mode_reglement) {
        // bodyFormData.append('mode_reglement', this.mode_reglement);
        for (let i = 0; i < this.mode_reglement.length; i++)
          if (this.mode_reglement[i].value != null) {
            bodyFormData.append(
              'mode_reglement[' + [i] + ']',
              this.mode_reglement[i].value
            );
          }
      }
      if (this.etat) {
        // bodyFormData.append('etat', this.etat);
        for (let i = 0; i < this.etat.length; i++)
          if (this.etat[i].value != null) {
            bodyFormData.append('etat[' + [i] + ']', this.etat[i].value);
          }
      }
      if (this.type) {
        for (let i = 0; i < this.type.length; i++)
          if (this.type[i].value != null) {
            bodyFormData.append('type[' + [i] + ']', this.type[i].value);
          }
      }
      if (this.num) {
        bodyFormData.append('num', this.num);
      }
      if (this.vendeur) {
        for (let i = 0; i < this.vendeur.length; i++)
          if (this.vendeur[i].value != null) {
            bodyFormData.append(
              'vendeur_id[' + [i] + ']',
              this.vendeur[i].value
            );
          }
      }
      if (this.categorie) {
        for (let i = 0; i < this.categorie.length; i++)
          if (this.categorie[i].value != null) {
            bodyFormData.append(
              'categorie_id[' + [i] + ']',
              this.categorie[i].value
            );
          }
      }
      if (this.acheteur) {
        for (let i = 0; i < this.acheteur.length; i++)
          if (this.acheteur[i].value != null) {
            bodyFormData.append(
              'acheteur_id[' + [i] + ']',
              this.acheteur[i].value
            );
          }
      }
      if (this.objet) {
        bodyFormData.append('objet', this.objet);
      }
      if (this.date_debut) {
        bodyFormData.append('date_debut', this.date_debut);
      }
      if (this.date_fin) {
        bodyFormData.append('date_fin', this.date_fin);
      }

      bodyFormData.append('column', 'id');

      bodyFormData.append('order', 'DESC');

      bodyFormData.append('famille', 'sci');

      if (this.selected.length === 0) {
        this.error = 'SVP séléctionner au moin une colonne';
        return;
      }
      //  else if (ids.length === 0) {
      //   this.error = 'SVP séléctionner au moin un document de type Facture';
      //   return;
      // }
      const response = await this.exportExcelFactureSci(bodyFormData);
      if (response) {
        this.$refs['refExportXlsModal'].hide();
        this.error = null;
      }
    },
    changeAllCheckColumns() {
      if (this.checkAllColumnF == true && this.checkAllColumn === true) {
        this.selected = [
          'type',
          'numero',
          'date',
          'client',
          'statut',
          'montant_net_ht',
          'montant_ttc',
          'objet',
          'montant_tva',
          'societe',
          'iban',
          'swift',
          'date_paiement',
          'mode_reglement',
          'nom_prod',
          'ref_prod',
          'qte_prod',
          'unite_prod',
          'pu_ht_prod',
          'reduction_prod',
          'net_ht_prod',
          'tva_prod',
          'ttc_prod',
          'description_prod',
          'categorie'
        ];
      } else if (
        this.checkAllColumnF == true &&
        this.checkAllColumn === false
      ) {
        this.selected = [
          'nom_prod',
          'ref_prod',
          'qte_prod',
          'unite_prod',
          'pu_ht_prod',
          'reduction_prod',
          'net_ht_prod',
          'tva_prod',
          'ttc_prod',
          'description_prod'
        ];
      } else if (
        this.checkAllColumnF == false &&
        this.checkAllColumn === true
      ) {
        this.selected = [
          'type',
          'numero',
          'date',
          'client',
          'statut',
          'montant_net_ht',
          'montant_ttc',
          'objet',
          'montant_tva',
          'societe',
          'iban',
          'swift',
          'date_paiement',
          'mode_reglement',
          'categorie'
        ];
      } else {
        this.selected = [];
      }
    },
    async handleSendMail(selectedAcheteurVendeur) {
      this.$bvModal.hide('mailModal');
      var bodyFormData1 = new FormData();
      for (let i = 0; i < this.computedGetDataToUse.length; i++) {
        bodyFormData1.append(
          'ids[' + [i] + ']',
          this.computedGetDataToUse[i].id
        );
      }
      bodyFormData1.append('parameter', 'Acheteur');
      var bodyFormData2 = new FormData();
      for (let i = 0; i < this.computedGetDataToUse.length; i++) {
        bodyFormData2.append(
          'ids[' + [i] + ']',
          this.computedGetDataToUse[i].id
        );
      }
      bodyFormData2.append('parameter', 'Vendeur');
      if (selectedAcheteurVendeur.includes('acheteur')) {
        const response = await this.sendMailFactureLibre(bodyFormData1);
        if (response.succes) {
          this?.getErrorMailingFactureLibre?.map(element => {
            if (element.societe.length > 0) {
              this.flashMessage.show({
                status: 'error',
                title: element.message,
                clickable: true,
                time: 0,
                message: element.societe
              });
            }
          });
          if (this.getSuccessSendMailsFactureLibre?.societe?.length > 0) {
            this.flashMessage.show({
              status: 'success',
              title: this.getSuccessSendMailsFactureLibre?.message,
              clickable: true,
              time: 0,
              message: this.getSuccessSendMailsFactureLibre?.societe
            });
          }
        } else {
          this.flashMessage.show({
            status: 'error',
            title: 'ERROR',
            clickable: true,
            time: 0,
            message: this.getOtherErrorMailFactureLibre
          });
        }
      }
      if (selectedAcheteurVendeur.includes('vendeur')) {
        const response2 = await this.sendMailFactureLibre(bodyFormData2);
        if (response2.succes) {
          this?.getErrorMailingFactureLibre?.map(element => {
            if (element.societe.length > 0) {
              this.flashMessage.show({
                status: 'error',
                title: element.message,
                clickable: true,
                time: 0,
                message: element.societe
              });
            }
          });
          if (this.getSuccessSendMailsFactureLibre?.societe?.length > 0) {
            this.flashMessage.show({
              status: 'success',
              title: this.getSuccessSendMailsFactureLibre?.message,
              clickable: true,
              time: 0,
              message: this.getSuccessSendMailsFactureLibre?.societe
            });
          }
        } else {
          this.flashMessage.show({
            status: 'error',
            title: 'ERROR',
            clickable: true,
            time: 0,
            message: this.getOtherErrorMailFactureLibre
          });
        }
      }
    },
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
    checkOneAvoirFunction(data) {
      if (data.checkAvoir === true) {
        this.factureAvoirChecked.push(data.id);
      } else {
        let index = this.factureAvoirChecked.findIndex(
          element => element.id === data.id
        );
        this.factureAvoirChecked.splice(index, 1);
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    }
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue'),
    AddFactureEnergieInvest: () =>
      import('../ThProject/frais/component/EInvest/addFacuteEnergieInvest.vue'),
    MailModal
  },
  async mounted() {
    if (localStorage.getItem('energie-invest-sci')) {
      this.searchValue = JSON.parse(
        localStorage.getItem('energie-invest-sci')
      ).search;
      this.statut = JSON.parse(
        localStorage.getItem('energie-invest-sci')
      ).statue;
      this.acheteur = JSON.parse(
        localStorage.getItem('energie-invest-sci')
      ).acheteur;
      this.vendeur = JSON.parse(
        localStorage.getItem('energie-invest-sci')
      ).vendeur;
      this.date_debut = JSON.parse(
        localStorage.getItem('energie-invest-sci')
      ).date_debut;
      this.date_fin = JSON.parse(
        localStorage.getItem('energie-invest-sci')
      ).date_fin;
    } else {
      this.setLocalStorageEnergieInvest();
    }
    this.dataSciOnLine();
    this.handleFilter();
  },
  directives: {
    ClickOutside
  }
};
</script>
<style scoped lang="scss">
.invest-frais {
  width: 100%;
  .entete {
    padding: 5px 10px;
  }

  .body-box-rapport {
    height: calc(100vh - 110px);
    margin: 5px;
    .customTbaleFraisComptaRh {
      max-height: calc(100vh - 195px) !important;
      height: calc(100vh - 195px) !important;
      margin-bottom: 0px;
    }
    .icon-plus {
      margin-right: 7px;
      color: rgb(1, 1, 1);
      font-size: 10px;

      &:hover {
        color: #8d8cb7;
      }
    }

    .trash-style {
      color: #dc3545;
      outline: none;
      font-size: 13px;
    }
  }

  .block-service-style {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
}

.iframe-position {
  display: block;
  margin: auto;
  width: 750px;
  height: 100%;
  margin-top: 5px;
}

.cutsom-modal-bootstrap-visualisation .modal-dialog {
  max-width: 750px !important;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;

  .modal-body {
    .row {
      height: 560px;
    }
  }
}
</style>
<style lang="scss">
.table-rapport-style .customTbaleFraisComptaRh {
  .item-details-style {
    margin: 2px 0px;
  }

  td,
  th {
    font-size: 10px;
    margin: 2px 0px;
    min-width: 130px;
  }

  .width-fixed {
    min-width: 130px;
  }
}
.export-button-style {
  width: 30px;
  height: 30px;
  .btn {
    border-radius: 30px !important;
  }
}
.custom-component-etat-facture {
  display: block;
  position: relative;

  .b-form-select-custom-etat {
    height: 18px;
    border-radius: 100px;
    border-color: #70707038;
    width: 75%;
    margin: auto;
    padding: 0px;
    -moz-box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 1px 5px -2px rgb(0 0 0 / 75%);
    min-width: 100px;
    font-size: 89%;
    position: relative;
    cursor: pointer;
  }

  &.open {
    .dropdown-menu {
      display: block;
      min-width: 9rem;
    }

    li {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.428571429;
      color: #333333;
      white-space: nowrap;
      font-size: 10px;

      &:hover {
        background-color: #4d4bac;
        color: white;
      }
    }
  }

  #content {
    position: relative;
    z-index: 1;
    text-align: center;
    font-weight: bold;
    font-family: Arial, sans-serif;
  }

  #background {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 1;
    border-radius: 100px;
    box-shadow: 2px 1px 5px -2px rgb(0 0 0 / 75%);
  }

  .icon-class-caret-down {
    margin-left: 3px;
    height: 1em !important;
  }
}

.white-color-custom-select {
  background-color: #fff;
  color: #000;
}

.red-color-custom-select {
  background: linear-gradient(
    to bottom,
    #f85032 0%,
    #f16f5c 0%,
    #f6290c 51%,
    #f02f17 71%,
    #e73827 100%
  );
  color: #fff;
}

.green-color-custom-select {
  background: linear-gradient(to bottom, #82bc40 0%, #47761d 100%);
  color: #fff;
}

.blue-color-custom-select {
  background: linear-gradient(#54b3ff, #0078d9);
  color: #fff;
}
.couleur-facture-global {
  background-color: #f0f1ff;
}
.fix-margin-td-avoir {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-right-width: 0px;
  min-width: 130px;
}
</style>
